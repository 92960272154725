body {
    background-color: #222;
    color: #fff;
    margin: 0;
    font-family: Arial, sans-serif;
}

.container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.gif-container {
    width: 60%; /* Adjust the width of the GIF */
    height: auto; /* Maintain aspect ratio */
}
